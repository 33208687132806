<template>
  <h1>User Management</h1>
  <h6>Looking for the Data Export? It has been moved to the
    <router-link :to="{name: 'ReportDashboard'}">Report Dashboard</router-link>
    .
  </h6>
  <div>
    <div class="row">

      <div class="col-sm-3">
        <form @submit.prevent="searchButtonClicked">
          <div class="row" style="text-align: center;">
            <label>Search</label>
            <input class="form-control" v-model="searchText" type="text" spellcheck="false"
                   placeholder="Name, Email, Organization" style="height: 41px;"/>
          </div>
          <div class="row">
            <div style="text-align: center;" class="col">
              <button type="submit" class="button btn-primary bi-search" @click="searchButtonClicked"
                      style="display: none"></button>
              <a type="submit" class="button btn-primary bi-search" @click="searchButtonClicked">
                Search</a>
            </div>
            <div class="col">
              <a class="button btn-primary" @click="routeToAddUser">New User</a>
            </div>
          </div>
        </form>
        <!--          <div>-->
        <!--              <button class="button btn-primary" @click="routeToAddUser">Add User</button>-->
        <!--          </div>-->
      </div>
      <div class="col-sm">
        <img src="/para_table_view_placeholder.jpg" width="250" height="170"
             alt="Seven Children standing in front of a wall with a map of the world on on the left, all smiling and carrying backpacks.">
      </div>

      <!--      <div class="row" style="margin-bottom: 1%">-->
      <div class="col-sm-3">

        <div class="row">
          <label>Filter</label>
          <input class="form-control mb-4" v-model="filterParam"
                 placeholder="Filter (name, email, institution)" style="height: 41px" :disabled="!isLoaded"/>
        </div>
        <div class="row">
          <label>Select a Role</label>
          <select class="form-select" v-model="selectedRoleType" @change="filterByRole" style="height: 41px"
                  :disabled="!isLoaded">
            <option :value="-1">User Type</option>
            <option :value="6" v-if="authUser.position.roleHierarchy === 1">ESU Administrator</option>
            <option :value="2" v-if="authUser.position.roleHierarchy <= 3">District Administrator</option>
            <option :value="5" v-if="authUser.position.roleHierarchy <= 4">Supervisor</option>
            <option :value="4">Student (Para Course)</option>
            <option :value="7">Student (Supervisor Course)</option>
            <option :value="1" v-if="authUser.position.roleHierarchy === 1">ESUCC/UNL</option>
            <option :value="3" v-if="authUser.position.roleHierarchy === 1">Nebraska Department of
              Education
            </option>
          </select>
        </div>
        <!--        </div>-->
      </div>

    </div>
    <div v-if="isLoaded" class="d-flex">
      <!--      <div class="d-flex justify-content-center align-items-center">-->
      <MDBDatatable
          :dataset="userList" pagination hover clickable-rows :search="filterParam"
          striped @render="setActions" :maxHeight="635" :entries="10" sm
          rows-text="Users per page:"
          class="justify-content-center align-items-center align-middle"
      />
      <!--      </div>-->
    </div>
  </div>
  <div v-if="showMenuModal">
    <Teleport to="body">
      <UserGroupMenu
          :id-user="selectedUserId"
          :groups="userGroups"
          @close="showMenuModal = false"
          :user-name="`${selectedUser.firstName} ${selectedUser.lastName}`"
      />
    </Teleport>
  </div>
</template>

<script>
import {computed, onBeforeMount, ref} from "vue";
import {MDBDatatable, MDBInput} from "mdb-vue-ui-kit";
import store from "@/store";
import {isSuperUser} from "@/RoleCheck";
import axios from "axios";
import {searchForUser} from "@/Helpers/ApiCalls/UserAPICalls";
import Swal from "sweetalert2";
import {allGroupsByUser} from "@/views/SuperUserViews/UserGroups/GroupApiRoutes";
import UserGroupMenu from "@/views/SuperUserViews/UserGroups/UserGroupMenu.vue";
import {esuNamePrep, formatDatatableData, formatEmailInDatatable} from "@/Helpers/DatatableFieldPrepFunctions";
import {API_URL} from "../../../Constants";
import ToggleSwitch from "@/components/ToggleSwitch/ToggleSwitch.vue";
import router from "@/router";
import StatusFlagSelect from "@/components/Generics/StatusFlagSelect.vue";

export default {
  name: "UserSearchV2",
  // eslint-disable-next-line vue/no-unused-components
  components: {StatusFlagSelect, MDBDatatable, MDBInput, UserGroupMenu, ToggleSwitch},
  setup() {
    const authUser = store.getters["auth/user"];
    const authToken = store.getters["auth/authenticated"];
    const searchText = ref("");
    const filterParam = ref("");
    const searchColumns = ref([]);
    const userRows = ref([]);
    const userList = ref({
      columns: [],
      rows: []
    });

    const showMenuModal = ref(false);
    const userGroups = ref([]);
    const selectedUserId = ref(-1);
    const isLoaded = ref(false);
    const selectedRoleType = ref(-1);


    onBeforeMount(() => {
      prepareColumns();
      getUserGroups();
      if (!isSuperUser(authUser.position.roleHierarchy)) {
        // Double call mounts data-mdb-number the table properly with data. Digging into why it's not populating the table properly otherwise.
        // This is a hacky fix that I'm working on solving, but it does create the on mount behavior we want.
        searchUser();
        searchUser();
      }


      if (localStorage.getItem('userSearchQuery')) {
        searchText.value = localStorage.getItem('userSearchQuery');
        searchUser();
      }
      if (localStorage.getItem('userFilterQuery')) {
        filterParam.value = localStorage.getItem('userFilterQuery');
      }
    })

    function searchButtonClicked() {
      isLoaded.value = false;
      searchUser();
    }

    async function searchUser() {
      filterParam.value = "";
      selectedRoleType.value = -1;
      if ((!isSuperUser(authUser.position.roleHierarchy) || (searchText.value.length >= 2))) {
        await axios.get(searchForUser(), {
          params: {
            keyword: searchText.value,
          },
          headers: {
            "Authorization": "Bearer " + authToken.value
          }
        }).then((result) => {
          userRows.value = setUserObjects(result.data);
          userList.value.rows = setUserObjects(result.data.sort((a, b) => a.firstName > b.firstName ? 1 : -1));
        }).finally(() => {
          localStorage.setItem('userSearchQuery', searchText.value);
          isLoaded.value = true;
        })
      } else {
        Swal.fire({
          title: "Missing Search Query",
          text: "Your elevated privileges would return too many results with an empty search query.\nPlease include search criteria.",
          confirmButtonText: "Okay",
          icon: "error"
        })
      }
    }

    function setUserObjects(userList) {
      let userObjList = [];
      for (let i = 0; i < userList.length; i++) {
        let enabled = userList[i]['accountEnabled'] !== null && userList[i]['accountEnabled'];
        let accountStatus = `<span style="display: none">${enabled}</span><button
                                 class="account-status-btn-enable btn-primary btn-sm"
                                 data-mdb-id="${btoa(userList[i]['idUser'])}"
                                 data-mdb-email="${userList[i]['email']}"
                                 data-mdb-enable="${enabled}"
                                  ${enabled ? 'disabled' : ''}
                                 style="border-radius: 10%; width: 64px;${enabled ? 'background-color: #4caf50; :hover:box-shadow {none}' : 'background-color: #a4a4a4; '}"
                                 >Active</button>
                                 <br/>
                                <button
                                 class="account-status-btn-disable btn-primary btn-sm mt-1"
                                 data-mdb-id="${btoa(userList[i]['idUser'])}"
                                 data-mdb-email="${userList[i]['email']}"
                                 data-mdb-enable="${enabled}"
                                 ${!enabled ? 'disabled' : ''}
                                 style="border-radius: 10%; width: 64px; ${!enabled ? 'background-color: #c82626; :hover:box-shadow {none}' : 'background-color: #a4a4a4;'}"
                                 >Inactive</button>

                                 `;
        let school_name = userList[i]['schoolName'] !== null ? formatDatatableData(userList[i]['schoolName']) : "";
        let district_name = userList[i]['districtName'] !== null ? formatDatatableData(userList[i]['districtName']) : "";
        let outstate_name = (userList[i]['outStateOrgName'] !== null && userList[i]['outStateOrgName'] !== undefined)
            ? formatDatatableData(userList[i]['outStateOrgName']) : "";
        let state_name = userList[i]['stateName'] !== null ? formatDatatableData(userList[i]['stateName']) : "";
        let username = formatDatatableData(`${userList[i]['firstName']} ${userList[i]['lastName']}`);
        let registration_date_display_name = userList[i]['registrationDate'] !== null ? userList[i]['registrationDate'] : "01/01/2022";
        let registration_date = new Date(registration_date_display_name);
        let date_in_unix_secs = registration_date.getTime() / 1000;
        let display_date_last_canvas_activity = userList[i]['lastCanvasActivity'] !== null ? userList[i]['lastCanvasActivity'] : "01/01/2022";
        let date_last_canvas_activity = new Date(display_date_last_canvas_activity);
        let last_canvas_activity_unix_secs = date_last_canvas_activity.getTime() / 1000;
        let user = {
          name: `<span style="display: inline-block"><span style="display: none;">${username}</span><button class="user-action-btn" data-mdb-id="${userList[i]['idUser']}"><i class="fa fa-ellipsis-vertical"></i></button><a href="/viewUser/${btoa(userList[i]['idUser'])}">${username}</a></span>`,
          email: userList[i]['email'],
          state: state_name,
          country: formatDatatableData(userList[i]['countryName']),
          esu: userList[i]['esuName'] !== null ? esuNamePrep(userList[i]['esuName']) : "",
          district: district_name,
          school: school_name,
          accountStatus: accountStatus,
          registrationDate: `<span style="display: inline-block;" ><span style="display: none;">${date_in_unix_secs}</span></span><span>${registration_date_display_name}</span>`,
          position: formatDatatableData(userList[i]['positionName']),
          educationalOrganization: outstate_name,
          reports: `<a href="/admin/report/user/${btoa(userList[i]['idUser'])}">${username}'s<br/>reports</a>`,
          idPosition: userList[i]['idPosition'],
          idUser: userList[i]['idUser'],
          firstName: userList[i]['firstName'],
          lastName: userList[i]['lastName'],
          lastCanvasActivity: `<span style="display: inline-block;" ><span style="display: none;">${last_canvas_activity_unix_secs}</span></span><span>${display_date_last_canvas_activity}</span>`,
          accountEnabled: enabled,
        }
        userObjList.push(user);
      }
      return userObjList;
    }

    function prepareColumns() {
      if (isSuperUser(authUser.position.roleHierarchy)) {
        userList.value.columns = [
          {label: "Name", field: "name", cellFormat},
          {label: "Reports", field: "reports", sort: false},
          {label: "Email", field: "email"},
          {label: "Acct. Status", field: "accountStatus"},
          {label: "Role", field: "position"},
          {label: "ESU", field: "esu"},
          {label: "District", field: "district"},
          {label: "School", field: "school", cellFormat},
          {label: "Institution", field: "educationalOrganization"},
          {label: "State", field: "state"},
          {label: "Country", field: "country"},
          {label: "Registration Date", field: "registrationDate"},
          {label: "Last Canvas Activity", field: "lastCanvasActivity"}
        ];
      } else if (authUser.inNebraska) {
        userList.value.columns = [
          {label: "Name", field: "name"},
          {label: "Reports", field: "reports", sort: false},
          {label: "Email", field: "email"},
          {label: "Acct. Status", field: "accountStatus"},
          {label: "Role", field: "position"},
          {label: "ESU", field: "esu"},
          {label: "District", field: "district"},
          {label: "School", field: "school", cellFormat},
          {label: "Registration Date", field: "registrationDate"},
          {label: "Last Canvas Activity", field: "lastCanvasActivity"}
        ];
      } else {
        userList.value.columns = [
          {label: "Name", field: "name"},
          {label: "Reports", field: "reports", sort: false},
          {label: "Email", field: "email"},
          {label: "Acct. Status", field: "accountStatus"},
          {label: "Role", field: "position"},
          {label: "Institution", field: "educationalOrganization"},
          {label: "State", field: "state"},
          {label: "Country", field: "country"},
          {label: "Registration Date", field: "registrationDate"},
          {label: "Last Canvas Activity", field: "lastCanvasActivity"}
        ];
      }
    }

    const setActions = () => {
      let group_elements = document.getElementsByClassName("user-action-btn");
      Array.prototype.forEach.call(group_elements, function (btn) {
        if (btn.getAttribute("click-listener") !== "true") {
          btn.addEventListener("click", () => {
            showMenuModal.value = !showMenuModal.value;
            selectedUserId.value = parseInt(btn.attributes['data-mdb-id'].value);
          });
          btn.setAttribute("click-listener", "true");
        }
      });
      //
      let enable_elements = document.getElementsByClassName("account-status-btn-enable");
      Array.prototype.forEach.call(enable_elements, function (btn) {
        if (btn.getAttribute("click-listener") !== "true" && btn.attributes['data-mdb-enable'].value === "false") {
          btn.addEventListener("click", () => {
            toggleEnabled(btn.attributes['data-mdb-email'].value, true, btn.attributes['data-mdb-id'].value);
          })
          btn.setAttribute("click-listener", "true");
        }
        // if (btn.attributes['data-mdb-enable'] === "true") {
        //   btn.setAttribute("disabled", "disabled");
        // }
      })

      let disable_elements = document.getElementsByClassName("account-status-btn-disable");
      Array.prototype.forEach.call(disable_elements, function (btn) {
        if (btn.getAttribute("click-listener") !== "true" && btn.attributes['data-mdb-enable'].value === "true") {
          btn.addEventListener("click", () => {
            toggleEnabled(btn.attributes['data-mdb-email'].value, false, btn.attributes['data-mdb-id'].value);
          })
          btn.setAttribute("click-listener", "true");
        }
        // if (btn.attributes['data-mdb-enable'].value === "false") {
        //   btn.setAttribute("disabled", "disabled");
        // }
      })

      // let acct_status_elements = document.getElementsByClassName("bool-acct-status");
      // Array.prototype.forEach.call(acct_status_elements, function (slider) {
      //   console.log(slider)
      //   // if (slider.getAttribute("click-listener") !== "true") {
      //   //   slider.setAttribute("onchange", () => {
      //   //     toggleEnabledV2(slider.attributes['data-mdb-email'], slider.attributes['data-mdb-enable'].value === "true", slider.attributes['data-mdb-id'].value)
      //   //   })
      //   //   slider.setAttribute("click-listener", "true")
      //   // }
      // })
    }


    async function getUserGroups() {
      await axios.get(allGroupsByUser(), {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        userGroups.value = result.data;
      })
    }

    const filterByRole = () => {
      if (selectedRoleType.value === -1) {
        userList.value.rows = userRows.value;
      } else {
        userList.value.rows = userRows.value.filter((u) => u.idPosition === selectedRoleType.value);
      }
    }

    const filterEnableFlag = (flag) => {
      console.log(userRows.value)
      if (flag === "All") {
        userList.value.rows = userRows.value;
      } else if (flag === "Active") {
        console.log(userRows.value.filter((u) => u.accountEnabled === true))
        userList.value.rows = userRows.value.filter((u) => u.accountEnabled === true);
      } else {
        userList.value.rows = userRows.value.filter((u) => u.accountEnabled !== true);
      }
      console.log(userList.value.rows);
    }

    async function toggleEnabled(email, enabled, idUser) {
      // console.log(`${enabled}, ${email}, ${idUser}`);
      const put_uri = API_URL + "/user/v2/enableUser/" + parseInt(atob(idUser));
      await axios.put(put_uri, {
            email: email,
            enabled: enabled
          },
          {
            headers: {
              Authorization: `Bearer ${authToken.value}`
            }
          }).then((result) => {
        Swal.fire({
          title: "User Access Updated",
          icon: 'success'
        }).then(() => {
          searchUser()
        })
      })
    }

    async function toggleEnabledV2(email, oldStatus, idUser) {
      const put_uri = API_URL + "/user/v2/enableUser/" + parseInt(atob(idUser));
      await axios.put(put_uri, {
            email: email,
            enabled: !oldStatus
          },
          {
            headers: {
              Authorization: `Bearer ${authToken.value}`
            }
          }).then((result) => {
        Swal.fire({
          title: "User Access Updated",
          icon: 'success'
        }).then(() => {
          searchUser()
        })
      })
    }

    const routeToViewUser = (index) => {
      router.push({name: 'ViewUser', params: {idUser: btoa(userList.value.rows[index].idUser)}})
    }

    function routeToAddUser() {
      router.push({name: 'AddUser'})
    }

    const cellFormat = computed(() =>
        userList.value.rows.map(row => {
          return {
            justifyContent: "center",
            alignItems: "center",
          };
        })
    );

    const selectedUser = computed(() => {
      return userList.value.rows.filter((u) => u.idUser === selectedUserId.value)[0];
    })

    return {
      userList,
      filterParam,
      searchColumns,
      searchText,
      showMenuModal,
      selectedUserId,
      userGroups,
      isLoaded,
      selectedRoleType,
      authUser,
      cellFormat,
      selectedUser,
      filterEnableFlag,
      setActions,
      searchButtonClicked,
      filterByRole,
      routeToAddUser,
      routeToViewUser
    }
  }
}
</script>

<style scoped>
.col-sm-3 {
  margin-right: 1%;
  margin-left: 1%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 10px;
}

.switch input {
  opacity: 0;
  height: 0;

}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 15px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 10px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #a4d541;
}

input:focus + .slider {
  box-shadow: 0 0 1px greenyellow;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round:before {
  border-radius: 50%;
}

.account-status-btn-disable, .account-status-btn-enable {
  box-shadow: black;
}

.account-status-btn-disable:disabled, .account-status-btn-enable:disabled {
  box-shadow: 0 0;
}

.account-status-btn-disable:hover, .account-status-btn-enable:hover {
  box-shadow: 2px 2px;
}

</style>