export const esuNamePrep = (esuName) => {
	let final_esu_name;
	if (esuName === "Unaffiliated" || esuName.includes("College")) {
		final_esu_name = esuName;
	} else if (esuName.includes("University of Nebraska at Lincoln")) {
		final_esu_name = "UNL";
	} else if (esuName.includes("NDE")) {
		final_esu_name = "NDE";
	} else {
		final_esu_name = esuName.replace("Educational Service Unit", "ESU");
		if (final_esu_name.includes("Coordinating")) {
			final_esu_name = "ESUCC";
		}
	}
	return final_esu_name;
}

export const formatDatatableData = (field) => {
	let arr = field.split(" ");
	let final_field = "";
	// let final_field =   `<span style="justify-content: center; align-content: center; display: flex">`;

	if (arr.length <= 1) {
		return field;
	}

	for (let i = 0; i < arr.length; i++) {
		final_field += `${arr[i]}`
		if (i !== arr.length - 1) {
			final_field += `<br/>`
		}
	}
	// final_field += `</span>`;
	return final_field;
}

export const formatEmailInDatatable = (email, charLimit = 30) => {
	let final_val = "";

	let char_count = 0;
	for (let i = 0; i < email.length; i++) {
		if (char_count < charLimit) {
			char_count++;
		} else {
			final_val += `<br/>`;
			char_count = 0;
		}
		final_val += email[i];
	}
	return final_val;
}

export const formatNameInDatatable = (firstName, lastName) => {
	let re = /([\s -])/
	// let first_arr = firstName.split(re);
	// let last_arr = lastName.split(re);
	//
	// let final_name = "";
	// for (let i = 0; i < )
}

export const formatCertificationInDatatable = (certName, passed, btnClass, certId) => {
	return passed ? `<span style="display: none">${passed}</span>
                    <button type="button" class="${btnClass} btn btn-sm btn-primary" data-mdb-id="${certId}" data-mdb-cert-name="${certName}">
                    ${passed ? "Yes" : "No"}
                    </button>` :
		`<span style="display: none">${passed}</span>
                    <button type="button" class="${btnClass} btn btn-sm btn-primary" data-mdb-id="${certId}" disabled data-mdb-cert-name="${certName}">
                    ${passed ? "Yes" : "No"}
                    </button>`;
}