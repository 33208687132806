<template>
  <select class="form-select" v-model="selectedStatus" style="height: 41px">
    <option v-for="(status, idx) in statusList" :key="idx" :value="status.text">{{status.text}}</option>
  </select>
</template>

<script>
import {onBeforeMount, ref, watch} from "vue";


export default {
  name: "StatusFlagSelect",
  props: {
    propStatus: {
      type: String,
      default: "All"
    }
  },
  setup(props, context) {
    const selectedStatus = ref("All");
    const statusList = ref([
      {text: "All"},
      {text: "Active"},
      {text: "Inactive"},
    ])

    onBeforeMount(() => {
      if (selectedStatus.value !== props.propStatus) {
        selectedStatus.value = props.propStatus;
      }
    })

    watch(() => props.propStatus, () => {
      selectedStatus.value = props.propStatus;
    })

    watch(() => selectedStatus.value, () => {
      context.emit("selectedStatus", selectedStatus.value);
    })

    return {
      selectedStatus,
      statusList,
    }
  },
};
</script>


<style scoped>

</style>