import {API_URL} from "../../../../Constants";

export const getGroupById = () => {
    return API_URL + "/userGroup/getById";
}

export const createGroup = () => {
    return API_URL + "/userGroup/create";
}

export const allGroupsByUser = () => {
    return API_URL + "/userGroup/allByUser";
}

export const updateGroup = () => {
    return API_URL + "/userGroup/update"
}

export const addUserToGroup = (idGroup, idUser) => {
    return API_URL + `/userGroup/${idGroup}/add/${idUser}`
}

export const removeUserFromGroup = (idGroup, idUser) => {
    return API_URL + `/userGroup/${idGroup}/remove/${idUser}`
}

export const getUserInGroup = (idGroup, idUser) => {
    return API_URL + `/userGroup/${idGroup}/userInGroup/${idUser}`
}

export const deleteGroupById = (idGroup) => {
    return API_URL + `/userGroup/delete/${idGroup}`
}