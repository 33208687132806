<template>
  <div style="white-space: nowrap; display: inline;">
    <span style="display: inline-flex; white-space: nowrap;"><input type="checkbox" :checked="checkedValue" @change="updateUserGroup" style="margin-right: 10px;">{{ groupData.groupName }}</span>
  </div>
</template>

<script>
import {computed, onBeforeMount, ref} from "vue";
import axios from "axios";
import {addUserToGroup, getUserInGroup, removeUserFromGroup} from "@/views/SuperUserViews/UserGroups/GroupApiRoutes";

export default {
  name: "UserGroupMenuItem",
  props: {
    groupData: Object,
    idUser: Number,
  },
  setup(props, context) {
    const checkedValue = ref();

    onBeforeMount(() => {
      userInGroup();
    })

    async function updateUserGroup() {
      checkedValue.value = !checkedValue.value;
      if (checkedValue.value) {
        await axios.put(addUserToGroup(props.groupData.idGroup, props.idUser))
            .then((result) => {
              // something here
            })
      } else {
        await axios.delete(removeUserFromGroup(props.groupData.idGroup, props.idUser))
            .then((result) => {
              // something here
            })
      }
    }

    async function userInGroup() {
      await axios.get(getUserInGroup(props.groupData.idGroup, props.idUser))
          .then((result) => {
            checkedValue.value = result.data;
          })
    }

    return {
      checkedValue,
      updateUserGroup
    }

  }
}
</script>

<style scoped>

</style>