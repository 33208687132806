<template>
  <Transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="$emit('close')">
        <div class="modal-container" @click.stop="">
          <div class="modal-header">
            <slot name="header">
              <h5 class="modal-title">Add {{ userName }} to Group</h5>
              <button type="button" class="btn-close" data-dismiss="modal" @click="$emit('close')"></button>
            </slot>
          </div>
          <slot name="body">
            <div class="modal-body">
              <div v-for="group in groups" :key="group.idGroup">
                <UserGroupMenuItem
                    :id-user="idUser"
                    :group-data="group"/>
              </div>
            </div>
          </slot>
          <slot name="footer">
            <div class="modal-footer">
              <span>If you would like to create a new group, <router-link :to="{name: 'CreateGroup'}">click here.</router-link></span>
            </div>

          </slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import UserGroupMenuItem from "@/views/SuperUserViews/UserGroups/UserGroupMenuItem";

export default {
  name: "UserGroupMenu",
  components: {UserGroupMenuItem},
  props: {
    idUser: Number,
    groups: Array,
    userName: String
  },
  setup(props) {


    return {}
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.modal-container {
  max-width: 350px;
  width: 80%;
  height: auto;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}


.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  overflow-y: auto;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

input {
  border: 1px solid black;
}

span {
  font-style: italic;
}
</style>